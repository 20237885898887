<template>
  <div class="sidebar-left">
    <div class="sidebar">
      <div class="sidebar-content todo-sidebar">
        <div class="todo-app-menu">
          <div class="add-task border-bottom py-1">Ürün Tanımla</div>
          <vue-perfect-scrollbar :settings="perfectScrollbarSettings" class="sidebar-menu-list scroll-area">
            <b-list-group class="list-group-filters">
              <b-list-group-item
                v-for="item in getUrunRoute"
                :key="item.title + $route.path"
                :to="item.route"
                :active="isDynamicRouteActive(item.route)"
                @click="$emit('close-left-sidebar')"
                :disabled="item.disable"
              >
                <feather-icon :icon="item.icon" size="18" class="mr-50" />
                <span class="align-text-bottom line-height-1">{{ item.title }}</span>
                <!-- <span class="light-success" v-if="!item.disable">
                  <feather-icon icon="CheckIcon" size="18" class="float-right" />
                </span> -->
              </b-list-group-item>
            </b-list-group>
          </vue-perfect-scrollbar>
          <div class="border-top">
            <b-list-group>
              <b-list-group-item class="d-flex justify-content-between align-items-center sifirla">
                <span>Ekleyen :</span>
                <b-badge variant="primary" pill class="rounded-0"> {{ form.save_user }}</b-badge>
              </b-list-group-item>
            </b-list-group>
            <b-list-group>
              <b-list-group-item class="d-flex justify-content-between align-items-center sifirla">
                <span>Güncelleyen :</span>
                <b-badge variant="primary" pill class="rounded-0"> {{ form.edit_user }}</b-badge>
              </b-list-group-item>
            </b-list-group>
            <b-list-group>
              <b-list-group-item class="d-flex justify-content-between align-items-center sifirla">
                <span>Son Güncelleme :</span>
                <b-badge variant="primary" pill class="rounded-0"> {{ form.edit_date | momentFull }} </b-badge>
              </b-list-group-item>
            </b-list-group>
            <b-list-group>
              <b-list-group-item class="d-flex justify-content-between align-items-center sifirla">
                <span>İzleme :</span>
                <b-badge variant="primary" pill class="rounded-0"> {{ form.hit }} </b-badge>
              </b-list-group-item>
            </b-list-group>
            <b-list-group>
              <b-list-group-item class="d-flex justify-content-between align-items-center sifirla">
                <span>Beğeni :</span>
                <b-badge variant="primary" pill class="rounded-0"> 0 </b-badge>
              </b-list-group-item>
            </b-list-group>
            <b-list-group>
              <b-list-group-item class="d-flex justify-content-between align-items-center sifirla">
                <span>Yorum :</span>
                <b-badge variant="primary" pill class="rounded-0"> 0 </b-badge>
              </b-list-group-item>
            </b-list-group>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import { isDynamicRouteActive } from '@core/utils/utils';
import { mapGetters } from 'vuex';
export default {
  components: {
    VuePerfectScrollbar,
  },
  props: {
    form: {
      type: Object,
      required: true,
      defualt: {},
    },
  },
  computed: {
    ...mapGetters(['getUrunRoute']),
  },
  data() {
    return {
      perfectScrollbarSettings: {
        maxScrollbarLength: 60,
      },
    };
  },
  setup() {
    return { isDynamicRouteActive };
  },
};
</script>

<style lang="scss">
.sifirla {
  padding: 5px 5px !important;
}
</style>
